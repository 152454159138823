import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'restful-checkbox',
    templateUrl: './restful-checkbox.component.html',
    styleUrls: ['./restful-checkbox.component.scss'],
    standalone: false
})
export class RestfulCheckboxComponent {
  @Input() question: any;
  @Input() form: any;
  @Input() hideOptional = true;
  @Input() hideLabel = false;
  @Input() bottomSubtext = false;
  @Output() checkedValue: EventEmitter<string> = new EventEmitter();

  get isValid() {
    return this.form.controls[this.question.key].valid;
  }
  get isDirty() {
    return this.form.controls[this.question.key].dirty;
  }
  get isTouched() {
    return this.form.controls[this.question.key].touched;
  }
  get errors() {
    return this.form.controls[this.question.key].errors;
  }

  constructor() {}

  setCheckedValue(title: string) {
    this.checkedValue.emit(title);
  }

  preventUncheck(checked: boolean) {
    if (checked) {
      event.preventDefault();
    }
  }
}
