<div
  [formGroup]="form"
  [class.is-invalid]="!isValid && (isDirty || isTouched)"
  [class.is-valid]="isValid && (isDirty || isTouched)">

  <span class="-fontMedium" *ngIf="!hideLabel">
    {{ question.label }}
    <span *ngIf="!question.required && !hideOptional"> (Optional)</span>
  </span>
    <div [formGroupName]="question.key" class="form -group-wrapper">
      <div *ngFor="let option of question.options" class="form -group-element-wrapper noSelector">
        <label class="form -group-element noSelector">
          <input 
            type="checkbox" 
            [formControlName]="option.value" 
            (click)="preventUncheck(option.defaultChecked); setCheckedValue(option.value)"/>
          <span 
            class="form -group-element-checkbox -checkbox-blue" 
            [ngClass]="{'-defaulted':option.defaultChecked, '-subCheckbox': question.subCheckbox}">
          </span>
          <span class="restful-checkbox -checkbox-text">
            {{ option.value }}
          </span>
          <span class="restful-checkbox -checkbox-verb" *ngIf="option.restVerb">
            <rest-verb-box [verb]="option.restVerb"></rest-verb-box>
          </span>
          <span class="restful-checkbox -checkbox-subtext" [ngClass]="{'-bottom-subtext':bottomSubtext}">
            {{ option.secondaryText  }}
          </span>
        </label>
      </div>
    </div>

  <span class="form -error -fontSmall" *ngIf="!isValid && (isDirty || isTouched)">
    <span *ngIf="errors.required">
      {{ question.label }} is required
    </span>
    <span *ngIf="errors.multiCheckboxRequired">
      {{ question.label }} is required
    </span>
  </span>
</div>

