import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { multiCheckboxRequiredValidator } from 'app/directives/multi-checkbox-required.directive';

@Component({
    selector: 'restful-checkbox-collection',
    templateUrl: './restful-checkbox-collection.component.html',
    styleUrls: ['./restful-checkbox-collection.component.scss'],
    standalone: false
})
export class RestfulCheckboxCollectionComponent implements OnInit {
  @Input() checkboxData: any;
  @Output() formOutput = new EventEmitter<UntypedFormGroup>();
  form: UntypedFormGroup;
  formattedCheckboxData = [];

  constructor() {}

  ngOnInit(): void {
    for (let x of this.checkboxData) {
      this.buildCheckboxes(x);
    }

    this.form = this.toFormGroup(this.formattedCheckboxData);
    this.formOutput.emit(this.form);
  }

  toFormGroup(questions: any) {
    const group: any = {};

    questions.forEach((question: { options: []; key: string; required: boolean }) => {
      const checkboxGroup: any = {};

      question.options.forEach((option: { value: string; checked: boolean }) => {
        checkboxGroup[option.value] = new UntypedFormControl(option.checked || false);
      });

      group[question.key] = question.required
        ? new UntypedFormGroup(checkboxGroup || '', multiCheckboxRequiredValidator())
        : new UntypedFormGroup(checkboxGroup || '');
    });

    return new UntypedFormGroup(group);
  }

  // Type this when API integration is possible
  buildCheckboxes(checkbox: any) {
    this.formattedCheckboxData.push({
      type: 'checkbox',
      key: checkbox.name,
      label: checkbox.name,
      subCheckbox: false,
      parent: checkbox.name,
      options: [
        {
          value: checkbox.name,
          secondaryText: checkbox.description,
          restVerb: '',
          checked: checkbox.enrolledByDefault,
          defaultChecked: checkbox.enrolledByDefault,
        },
      ],
    });

    for (const x of checkbox.testCases) {
      this.formattedCheckboxData.push({
        type: 'checkbox',
        key: x.name,
        label: x.name,
        subCheckbox: true,
        parent: checkbox.name,
        options: [
          {
            value: x.name,
            secondaryText: x.description,
            restVerb: x.httpMethod,
            checked: checkbox.enrolledByDefault ? x.enrolledByDefault : false,
            defaultChecked: x.enrolledByDefault,
          },
        ],
      });
    }
  }

  setAllDefaults(event: any) {
    if (this.formattedCheckboxData.find((x) => x.key === event).options[0].defaultChecked === false) {
      this.formattedCheckboxData = this.formattedCheckboxData.map((y: any) => {
        if (y.parent === event) {
          y.options[0].checked = !y.options[0].checked;
        }

        return y;
      });
    }
    this.form = this.toFormGroup(this.formattedCheckboxData);
    this.formOutput.emit(this.form);
  }
}
