<div *ngFor="let case of formattedCheckboxData">
  <div *ngIf="!case.subCheckbox; then headingBox else subBoxes"></div>
  <ng-template #headingBox>
    <div class="restful-checkbox-collection -heading-checkbox">
      <restful-checkbox [question]="case" [form]="form" [hideOptional]="true" [hideLabel]="true" [bottomSubtext]="true" (checkedValue)="setAllDefaults($event)"></restful-checkbox>
    </div>
  </ng-template>
  <ng-template #subBoxes>
    <div class="restful-checkbox-collection -sub-checkbox">
      <restful-checkbox [question]="case" [form]="form" [hideOptional]="true" [hideLabel]="true" ></restful-checkbox>
    </div>
  </ng-template>
</div>